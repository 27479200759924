import React from "react"
import Reveal from "react-reveal/Reveal"

import Tabs from "./components/tabs"
import Bottom from "./components/Bottom"
import "./fonts/index.css"
import "./styles.less"
import styles from "./styles.module.less"

const BlackHistoryMonth = () => {
  return (
    <article className={styles.BlackHistoryMonth}>
      <Reveal>
        <div className={styles.hero}>
          <div className={styles.heroHeadline}>
            <h1 className={styles.heroTitle}>Uncovering Black Excellence</h1>
            <div className={styles.circular}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 150.71 151.71"
              >
                <defs></defs>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_4" data-name="Layer 4">
                    <text
                      class="cls-1"
                      transform="translate(18.55 64.53) rotate(-80.17)"
                    >
                      R
                    </text>
                    <text
                      class="cls-1"
                      transform="matrix(0.36, -0.93, 0.93, 0.36, 20.31, 54.17)"
                    >
                      E
                    </text>
                    <text
                      class="cls-1"
                      transform="matrix(0.53, -0.85, 0.85, 0.53, 23.77, 45.14)"
                    >
                      L
                    </text>
                    <text
                      class="cls-1"
                      transform="matrix(0.67, -0.74, 0.74, 0.67, 28.47, 37.37)"
                    >
                      E
                    </text>
                    <text
                      class="cls-1"
                      transform="matrix(0.82, -0.58, 0.58, 0.82, 35.04, 29.86)"
                    >
                      A
                    </text>
                    <text
                      class="cls-1"
                      transform="translate(45.01 22.83) rotate(-22.48)"
                    >
                      R
                    </text>
                    <text
                      class="cls-1"
                      transform="matrix(0.99, -0.16, 0.16, 0.99, 54.56, 18.59)"
                    >
                      N
                    </text>
                    <text
                      class="cls-1"
                      transform="translate(68.07 16.82) rotate(0.35)"
                    >
                      {" "}
                    </text>
                    <text
                      class="cls-1"
                      transform="matrix(0.98, 0.18, -0.18, 0.98, 72.21, 16.42)"
                    >
                      H
                    </text>
                    <text
                      class="cls-1"
                      transform="translate(85.14 19.14) rotate(20.73)"
                    >
                      I
                    </text>
                    <text
                      class="cls-1"
                      transform="matrix(0.87, 0.48, -0.48, 0.87, 89.84, 20.7)"
                    >
                      S
                    </text>
                    <text
                      class="cls-1"
                      transform="matrix(0.77, 0.64, -0.64, 0.77, 98.23, 25.23)"
                    >
                      T
                    </text>
                    <text
                      class="cls-1"
                      transform="matrix(0.6, 0.8, -0.8, 0.6, 106, 31.3)"
                    >
                      O
                    </text>
                    <text
                      class="cls-1"
                      transform="translate(114.4 42.77) rotate(67.12)"
                    >
                      R
                    </text>
                    <text
                      class="cls-1"
                      transform="translate(118.5 52.1) rotate(78.99)"
                    >
                      Y
                    </text>
                    <text
                      class="cls-2"
                      transform="translate(5.76 74.96) rotate(78.47)"
                    >
                      R
                    </text>
                    <text
                      class="cls-2"
                      transform="translate(8.58 87.73) rotate(67.29)"
                    >
                      E
                    </text>
                    <text
                      class="cls-2"
                      transform="translate(13.4 98.8) rotate(56.71)"
                    >
                      L
                    </text>
                    <text
                      class="cls-2"
                      transform="translate(19.83 108.35) rotate(46.12)"
                    >
                      E
                    </text>
                    <text
                      class="cls-2"
                      transform="translate(28.8 117.5) rotate(33.62)"
                    >
                      A
                    </text>
                    <text
                      class="cls-2"
                      transform="translate(41.56 125.58) rotate(20.92)"
                    >
                      R
                    </text>
                    <text
                      class="cls-2"
                      transform="matrix(0.99, 0.13, -0.13, 0.99, 54.18, 130.31)"
                    >
                      N
                    </text>
                    <text
                      class="cls-2"
                      transform="translate(69.98 131.85) rotate(-2.41)"
                    >
                      {" "}
                    </text>
                    <text
                      class="cls-2"
                      transform="translate(76.43 131.83) rotate(-12.25)"
                    >
                      H
                    </text>
                    <text
                      class="cls-2"
                      transform="matrix(0.93, -0.38, 0.38, 0.93, 91.48, 128.04)"
                    >
                      I
                    </text>
                    <text
                      class="cls-2"
                      transform="translate(97.85 125.51) rotate(-30.54)"
                    >
                      S
                    </text>
                    <text
                      class="cls-2"
                      transform="translate(108.16 119.26) rotate(-41.69)"
                    >
                      T
                    </text>
                    <text
                      class="cls-2"
                      transform="matrix(0.58, -0.82, 0.82, 0.58, 117.75, 110.74)"
                    >
                      O
                    </text>
                    <text
                      class="cls-2"
                      transform="translate(127.32 96.23) rotate(-68.69)"
                    >
                      R
                    </text>
                    <text
                      class="cls-2"
                      transform="translate(131.88 84.19) rotate(-80.48)"
                    >
                      Y
                    </text>
                  </g>
                </g>
              </svg>
            </div>
          </div>

          <div className={styles.heroCopy}>
            <div className={styles.headerDesc}>
              For 400 years, African Americans have been forced to live, work,
              and survive in an alternate America, experiencing and recording a
              different version of our nation’s history from the one taught in
              textbooks — one that goes well beyond slavery and the Civil Rights
              Movement.
            </div>
            <div className={styles.headerDetails}>
              <p>
                <i>History</i>, like how an African named Onesimus created
                inoculation in the early 18th century. <i>History</i>, like how
                one in four cowboys was Black. <i>History</i>, like how Bessie
                Coleman became the first Black person to hold an international
                pilot’s license. <i>History</i>, like how Betty Boop was
                inspired by Harlem lounge singer Esther Jones, for which she got
                no credit or notoriety.
              </p>
              <p>
                This Black History Month, BET is uncovering the Black excellence
                that has been buried by our society for so long — unsung people,
                places, and events that show us where we came from, where we’re
                going, and how Black history <i>is</i> America’s history.
              </p>
            </div>
          </div>
        </div>

        <div id="top-tabs-anchor" className={styles.anchor} />
        <div
          style={{
            backgroundImage: `url("https://eroi-s3.s3.us-west-2.amazonaws.com/thesis-web/divider.svg")`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100vw",
            width: "100%",
            height: "20px",
          }}
        />
      </Reveal>

      <Tabs />
      <Bottom />
    </article>
  )
}

export default BlackHistoryMonth
