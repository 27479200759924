import React from "react"
import Reveal from "react-reveal/Reveal"

import steelheadsMobile from "./img/Steelheads-mobile.svg"
import rosebudsMobile from "./img/Rosebuds-mobile.svg"
import steelheads from "./img/Steelheads.svg"
import rosebuds from "./img/Rosebuds.svg"

import diamond from "./img/diamond.svg"
import player from "./img/player.svg"
import bat from "./img/bat.svg"
import rose from "./img/rose.svg"

import ExpandCollapse from "../collapsedExpand"

import styles from "./styles.module.less"

const WeekTwo = () => {
  return (
    <div className={styles.body}>
      <Reveal>
        <section id="week2">
          <div className={styles.weekIntro}>
            <div className={styles.weekHeroCopy}>
              <h1>A League of Their Own</h1>
              <p>Excellence is always — even when no one is watching.</p>
              <span className={styles.quote}>
                “Don’t feel sorry for the Black baseball player. Feel sorry for
                the ones who didn’t get to see them play.” — Buck O’Neil
              </span>
            </div>
          </div>
        </section>
        {/* render mobile image */}
        <div className={styles.story1HeroContainerMobile}>
          <img src={steelheadsMobile} className={styles.story2HeroMobile} />
        </div>
        {/* render desktop image */}
        <div className={styles.story1HeroContainer}>
          <img src={steelheads} className={styles.story2Hero} />
        </div>

        <div className={styles.story}>
          <div className={styles.details}>
            <Reveal>
              <h1>01. </h1>
              <div className={styles.p1}>
                In the wake of World War II, the West Coast had an infusion of
                color as thousands of African Americans, both trade workers and
                military service members, migrated to pursue financial and
                social Black excellence. With more liberal social attitudes and
                significant economic opportunities, the Pacific states became a
                haven for those wanting to escape the harsh realities of the
                Midwest and the Jim Crow South.
              </div>
            </Reveal>
            <div>
              <br />
            </div>
            <Reveal>
              <div className={styles.columns}>
                <p>
                  Investors recognized this and desired to tap into that market
                  by recreating the success of other Negro Baseball Leagues —
                  and quickly moved to form their own. Established in the spring
                  of 1946 by Abe Saperstein (founder of the Harlem
                  Globetrotters) and overseen by Jesse Owens (“World’s Fastest
                  Man”), the West Coast Negro Baseball League was a minor league
                  that had six teams, including the Seattle Steelheads and the
                  Portland Rosebuds.
                </p>
                <img src={diamond} alt="" className={styles.columnImgRT} />
              </div>
            </Reveal>
            <Reveal>
              <p className={styles.focusCopy}>
                Scheduled to play a 110-game season, the league lasted all of
                three months.
              </p>
            </Reveal>
            <br />
            <Reveal>
              <p>
                There are barely any pictures of its existence, and it’s so
                little recognized that Jesse Owens' museum was surprised to
                discover he had any involvement. <i>Why?</i>
              </p>
            </Reveal>

            <Reveal>
              <p>
                Saperstein spared no expense getting his Seattle team, nicknamed
                the “Steelies” after a type of Pacific salmon, off the ground.
                He moved players from his other organization, the already famous
                Globetrotters basketball team, and assigned them to the
                Steelhead roster. Prominent players included first baseman Herb
                Simpson, outfielder Zell Miles, catcher Everett “Ziggy” Marcell,
                and others. After setting the roster, Saperstein turned over
                daily operations of the team to local Black businessmen.
              </p>
            </Reveal>
            <Reveal>
              <p>
                The Steelies played their first game on June 1, 1946 in Sick's
                Stadium, named after Emil Sick, owner of the Rainier Brewing
                Company and home of the Seattle Rainiers. In front of a mixed
                home crowd of 2,500, the Steelies split a doubleheader, going
                1–1 with their opponent, the San Diego Tigers. In addition to
                their league games, the Steelheads played exhibition games all
                over the region, in places such as Bellingham, Spokane, and
                Portland.{" "}
              </p>
            </Reveal>
            <div className={styles.columns}>
              <img src={player} className={styles.columnImgLT} />
              <div style={{ flexDirection: "column" }}>
                <Reveal>
                  <p className={styles.focusCopy}>
                    Optimism was high, and the league anticipated success due to
                    the influx of African Americans to the West Coast and the
                    popularity of baseball in the Black community.
                  </p>
                  <p>
                    Clubs in other parts of the country had been turning a
                    profit since the ’20s.
                  </p>
                </Reveal>

                <Reveal>
                  <p>
                    After the initial luster wore off though, attendance and
                    media coverage dwindled.
                  </p>
                </Reveal>
              </div>
            </div>

            <Reveal>
              <p className={styles.focusCopy}>
                The Steelheads and other teams in the league failed to gain a
                foothold in public interest. Despite a winning season in
                Seattle, the Steelies never drew large crowds during the
                entirety of their run.
              </p>
            </Reveal>
            <br />
            <Reveal>
              <p>
                Unable to live off the gate, the first-place Steelheads played
                their last game in Sick’s Stadium in September 1946. Saperstein
                regained control of the team and eventually renamed them after
                the Harlem Globetrotters, relying on his far more successful
                venture’s reputation of showy athleticism. The team went
                “barnstorming,” or playing exhibition games in rural areas,
                until the 1950s, years after Jackie Robinson broke the Major
                League Baseball color barrier.
              </p>
            </Reveal>
          </div>
        </div>
        {/* story2 */}

        {/* render mobile image */}
        <div className={styles.story2HeroContainerMobile}>
          <img src={rosebudsMobile} className={styles.story2HeroMobile} />
        </div>
        {/* render desktop image */}
        <div className={styles.story2HeroContainer}>
          <img src={rosebuds} className={styles.story2Hero} />
        </div>

        <div className={styles.story}>
          <div className={styles.details}>
            <Reveal>
              <h1>02. </h1>
              <div className={styles.p1}>
                Seattle’s interstate rival, the Portland Rosebuds (or the
                Portland Roses as they were more commonly called) didn’t fare
                much better. Owned by Owens, the 1936 Berlin Olympic four-time
                gold medalist, the Roses comprised experienced Negro League
                players like pitcher Al Jones from the Memphis Red Sox and
                infielder Blue Dunn from the Miami Ethiopian Clowns. Owens was
                committed to making this team, and the league overall, a raging
                success after losing $25,000 promoting another Negro League team
                in 1936.
              </div>
            </Reveal>
            <br />

            <div className={styles.columns}>
              <div style={{ flexDirection: "column" }}>
                <Reveal>
                  <p className={styles.focusCopy}>
                    “It’s easier to start from scratch on the track than at the
                    bank. I buckled down and proved to myself that I had the
                    talent to think as well as run,” Owens later said.
                  </p>
                </Reveal>

                <Reveal>
                  <p className={styles.focusCopy}>
                    By July, the club reached second place in the standings, but
                    the same issues plaguing the entire league reared their head
                    in Portland and the team folded.
                  </p>
                </Reveal>
              </div>
              <img
                src={bat}
                className={styles.columnImgRT}
                style={{ marginTop: "5%" }}
              />
            </div>
            <div className={styles.columns}>
              <Reveal>
                <img
                  src={rose}
                  className={styles.columnImgLT}
                  style={{ marginTop: " 0% ", marginRight: "8%" }}
                />
                <p>
                  And while the league faded into obscurity, the prominence of
                  Black athletes rose, as within the next year, Jackie Robinson,
                  an alum of the Kansas City Monarchs in the Negro American
                  League, was offered a contract to play for Major League
                  Baseball’s Brooklyn Dodgers, reintegrating professional
                  baseball after almost 80 years. Other talented Negro League
                  players were quickly signed to formerly all-white rosters,
                  depleting the remaining Negro Leagues until their eventual
                  demise.
                </p>
              </Reveal>
            </div>
            <Reveal>
              <p className={styles.focusCopy}>
                Owens became the running coach of the New York Mets in 1965, and
                taught the next generation of Black athletes firsthand.
              </p>
            </Reveal>
          </div>
        </div>
      </Reveal>
    </div>
  )
}

export default WeekTwo
