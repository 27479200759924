import React from "react"
import Reveal from "react-reveal/Reveal"

import steelheadsMobile from "./img/AlbinaRiots-mobile.svg"
import rosebudsMobile from "./img/SeattleBPP-mobile.svg"
import steelheads from "./img/AlbinaRiots.svg"
import rosebuds from "./img/SeattleBPP.svg"

import megaphone from "./img/megaphone.svg"
import crowd from "./img/crowd.svg"
import pick from "./img/pick.svg"
import panther from "./img/panther.svg"

import styles from "./styles.module.less"

const WeekThree = () => {
  return (
    <div className={styles.body}>
      <section id="week3">
        <div className={styles.weekIntro}>
          <div className={styles.weekHeroCopy}>
            <h1>Activism and Unrest</h1>
            <p>
              Years of resentment and racial tension boiled over two
              unforgettable days.
            </p>
            <span className={styles.quote}>
              “How do we stop violence, looting, and riots? By making sure
              people have what they need to thrive.” — Alice Garza
            </span>
          </div>
        </div>
      </section>
      <Reveal>
        <div className={styles.story1HeroContainerMobile}>
          <img src={steelheadsMobile} className={styles.story2HeroMobile} />
        </div>

        <div className={styles.story1HeroContainer}>
          <img src={steelheads} className={styles.story2Hero} />
        </div>
      </Reveal>
      <div className={styles.story}>
        <div className={styles.details}>
          <Reveal>
            <h1>01. </h1>
            <div className={styles.p1}>
              In 1967, Black people in Oregon were fed up.
            </div>
            <div>
              <br />
            </div>

            <p>
              Consistently the target of discrimination from both a statewide
              and local level since the state’s birth, African Americans were
              subjected to cruel and unfair treatment, just like in other parts
              of the country. Early provisions to the Oregon Constitution
              forbade Blacks to own property within the state, a law that, while
              made null and void by the 14th Amendment in the US Constitution,
              was Oregon law by “popular demand” until 1926. And the redlining
              of Black Portlanders into the Albina district of the Rose City
              started as early as the ’30s.
            </p>
            <p>
              Redlining occurs when the government and the private sector deny
              goods and services to communities.
            </p>
          </Reveal>
          <Reveal>
            <p className={styles.focusCopy}>
              The City of Portland made a concerted effort to redline African
              American and other minorities into specific neighborhoods, North
              Portland especially.
            </p>
          </Reveal>
          <br />

          <div className={styles.columns}>
            <div style={{ flexDirection: "column" }}>
              <Reveal>
                <p>
                  Areas “unfit” for development and lending were marked in red
                  on a map. “Low risk” or predominantly white areas were marked
                  in green and the intermediate regions marked in blue.
                  Consequently, communities that were “red” were left in a state
                  of disrepair or underdevelopment, with any attempts at
                  revitalization being flatly denied. Businesses that folded
                  were not allowed to be replaced, leaving city blocks crumbling
                  and dilapidated.
                </p>
              </Reveal>
              <Reveal>
                <p>
                  Add an increase in crime and over-policing, and it’s easy to
                  see why Black people were upset nationwide, not just in
                  Albina. Referred to as the <i>Long, Hot Summer of 1967</i>,
                  159 race-related riots broke out in cities across the country.
                  On July 30, 1967, Portland joined this list in what is now
                  known as the Albina Riot.{" "}
                </p>
              </Reveal>
            </div>
            <img
              src={megaphone}
              className={styles.columnImgRT}
              style={{ marginTop: "3%" }}
            />
          </div>
          <Reveal>
            <p>
              That day, a group of 150 were waiting in Irving Park to hear
              speeches about social justice, specifically by Eldridge Cleaver,
              the Minister of Information for the Black Panther Party. Local law
              enforcement, anticipating a riot, increased their presence at the
              park.
            </p>
          </Reveal>
          <Reveal>
            <p className={styles.focusCopy}>
              Cleaver never showed up; rumors spread he’d been detained by
              police. The combination of his no-show, years of tension with
              Portland PD, and a desperation to be heard by Black Portlandians
              brought the situation to a head.
            </p>
          </Reveal>

          <div className={styles.columns}>
            <Reveal>
              <img
                src={crowd}
                className={styles.columnImgLT}
                style={{
                  marginTop: " 8% ",
                  marginRight: "2%",
                  maxWidth: "585px ",
                }}
              />
              <div style={{ flexDirection: "column" }}>
                <p>
                  Soon, Black teens began throwing rocks at windows of local
                  stores, and a group of five attacked a white park employee.
                  Within an hour, the chaos had spread into the neighborhood,
                  forcing law enforcement to close more than 30 blocks.
                  Eventually, neighborhood buildings were set on fire by
                  arsonists.{" "}
                </p>
                <p>
                  In response to the unrest, more than 200 Portland Police were
                  called in to quell the rioting with another 200 on standby.
                  The rioting continued into the night but was finished by the
                  morning, only to pick up again the next night. As a result of
                  the civil disturbance, more than 115 arrests were made, and
                  more than $50,000 ($390,000 in today’s economy) in property
                  damage was done.
                </p>
              </div>
            </Reveal>
          </div>

          <Reveal>
            <p className={styles.focusCopy}>
              A little more than a year later, Dr. Martin Luther King Jr.
              encapsulated the summer of riots succinctly: “A riot is the voice
              of the unheard.”{" "}
            </p>
          </Reveal>
        </div>
      </div>

      <div className={styles.story2HeroContainerMobile}>
        <img src={rosebudsMobile} className={styles.story2HeroMobile} />
      </div>

      <div className={styles.story2HeroContainer}>
        <img src={rosebuds} className={styles.story2Hero} />
      </div>

      <div className={styles.story}>
        <div className={styles.details}>
          <Reveal>
            <h1>02. </h1>
            <div className={styles.p1}>
              To move forward with equity and inclusion, the African American
              community had to assert themselves into society by any means
              necessary.
            </div>
            <div>
              <br />
            </div>
            <p>
              The Black Panther Party was a Black paramilitary group dedicated
              to revolutionary politics that embodied this new line of thinking.
              Bursting onto the scene a year before by marching into the
              California State Legislature to protest a bill banning firearms in
              public, the Panthers forcefully captured the nation’s attention,
              both good and bad. Although membership was never large and the
              party disbanded quickly, its purpose and mission were vital to the
              Pacific Northwest, helping introduce this region to a new form of
              political action.
            </p>
            <p>
              Founded in 1966 in Oakland, California, by Bobby Seale and Huey
              Newton, the Black Panther Party for Self Defense, or BPP, was
              created as a response to police brutality and racial injustice in
              Black neighborhoods.{" "}
            </p>
          </Reveal>

          <div className={styles.columns}>
            <div style={{ flexDirection: "column" }}>
              <Reveal>
                <p className={styles.focusCopy}>
                  Highlighted by the <i>Ten-Point Program</i>, a manifesto
                  demanding freedom, the “power to determine the destiny of our
                  Black community” and the right to armed self-defense, the
                  group quickly attracted both supporters and detractors.
                </p>
                <p>
                  In an attempt to hold law enforcement accountable, the
                  Panthers started “copwatching” — organizing their own patrols
                  of police activity in Black neighborhoods. Soon after, the
                  Panthers found themselves in the crosshairs of the police,
                  engaging in deadly firefights and establishing a reputation as
                  urban militant guerrillas.
                </p>
              </Reveal>
            </div>
            <img
              src={pick}
              className={styles.columnImgRT}
              style={{ marginTop: "5%" }}
            />
          </div>

          <Reveal>
            <p>
              The Seattle chapter was formed in April 1968 by Aaron Dixon, his
              brother Elmer, Anthony Ware, and other future Panthers who had
              attended the funeral of 17-year-old Bobby Hutton, a young Panther
              who had been killed by the police. The future Seattle Panthers
              were students at the University of Washington, where they founded
              the Black Student Union, organizing sit-ins and protests.{" "}
            </p>
          </Reveal>
          <Reveal>
            <p className={styles.focusCopy}>
              While attending a Black Student Union conference in Oakland, the
              group met Newton and Seale. Inspired by Seale’s passionate
              speeches and interest in learning more about the{" "}
              <i>Ten-Point Program</i>, they committed to founding the Black
              Panthers’ Washington State chapter.
            </p>
          </Reveal>
          <div className={styles.columns}>
            <Reveal>
              <img
                src={panther}
                className={styles.columnImgLT}
                style={{
                  marginTop: " 4% ",
                  marginRight: "5%",
                  maxWidth: "635px",
                }}
              />
              <p>
                Like their Oakland predecessors, they quickly found themselves
                drawing the ire of their local police department, leading to a
                raid on Panther headquarters and the arrest of Dixon and deputy
                chairman Curtis Harris over the alleged theft of a $3,000
                typewriter. The subsequent demonstrations for his release
                incited a riot, Seattle’s first, which lasted for three days
                while also encouraging the Panthers to increase their endeavors
                in combating racial inequity.
              </p>
            </Reveal>
          </div>
          <Reveal>
            <p className={styles.focusCopy}>
              Soon after a jury found Dixon not guilty and he was released, the
              Panthers received orders from Newton and the Oakland branch to
              establish “survival programs” for Seattle’s Black communities that
              offered free breakfasts, free legal aid, and later, founded the
              Sidney Miller Free Medical Clinic, which included baby wellness
              programs, genetic counseling, and sickle cell testing.
            </p>
            <p>
              Later rebuilt to be larger, it was renamed the Carolyn Downs
              Family Medical Center after a Panther who died of cervical cancer,
              and it still serves as an access point for low-income and minority
              residents all over Seattle to this day.
            </p>
          </Reveal>

          <Reveal>
            <p>
              After Newton’s release from prison, the Panthers’ centralized
              leadership had a split in ideologies. Some members, like Newton
              himself, wanted to move away from the aggressive tactics they
              started with and focus their attention on local government and
              social services. This led to a complete schism between Cleaver and
              Newton, and the Panthers lost members in droves. Eventually, the
              split turned deadly, with each respective group carrying out
              assassinations against rival members, leading to four deaths.
            </p>
          </Reveal>
          <Reveal>
            <p>
              Seattle wasn’t immune from the change. Many members felt
              embittered by the reality of the party and resigned. By 1972,
              Newton ordered most Panther chapters to be shut down and folded
              back into the Oakland branch. Dixon, still the captain of the
              party, eventually moved to the Bay Area and worked closely with
              Newton, Seale, and the rest of Panther leadership, leaving Elmer
              in charge of the group’s remnants. Reduced from their peak of 300
              members to just a handful, the Seattle Panthers followed Newton’s
              vision of the party, primarily focusing on community programs
              until their disbandment in 1978, following the Oakland branch’s
              collapse.
            </p>
          </Reveal>
          <Reveal>
            <p>
              Today, we honor the long-lasting legacy of the Seattle Black
              Panthers — and the courage and inspiration they gave to the future
              generation of social justice activists, not just in the Pacific
              Northwest but worldwide.
            </p>
          </Reveal>
        </div>
      </div>
    </div>
  )
}

export default WeekThree
