import React from "react"
import Reveal from "react-reveal/Reveal"
import georgeWashingtonBush from "./img/GWB2.svg"
import manuelLopes from "./img/ML2.svg"

import ExpandCollapse from "../collapsedExpand"

import styles from "./styles.module.less"

const WeekOne = () => {
  return (
    <div className={styles.body}>
      <section id="week1">
        <div className={styles.weekIntro}>
          <div className={styles.weekHeroCopy}>
            <h1>Westward Expansion</h1>
            <p>
              Black people manifested their own destiny by going west —<br />
              and finding excellence along the way.
            </p>
            <span className={styles.quote}>
              “Go West, young man, and grow up with the country.” — Horace
              Greeley
            </span>
          </div>
        </div>

        <div className={styles.story1HeroContainer}>
          <img src={georgeWashingtonBush} className={styles.story1Hero} />
        </div>

        <div className={styles.story}>
          <div className={styles.details}>
            <Reveal>
              <h1>01. </h1>
              <div className={styles.p1}>
                George Washington Bush wanted to experience the equity and
                liberty our country was founded on, so he did what many others
                had done before and headed west on the Oregon Trail.
              </div>
            </Reveal>
            <div>
              {" "}
              <br />
            </div>

            <Reveal>
              <p className={styles.focusCopy}>
                Dreaming of freedom and prosperity, he only found discrimination
                and ignorance in the Oregon Territory — but in the land just
                north, his dream would come true.
              </p>
            </Reveal>
            <Reveal>
              <p>
                Born to an African American father and an Irish mother, Bush was
                raised and taught in Philadelphia by Quakers. He served under
                Andrew Jackson in the War of 1812, after which he became a fur
                trapper. He then relocated to the Oregon Territory, where he
                worked for the Hudson’s Bay Company. He eventually settled in
                Missouri, where he married, and purchased 80 acres of land to
                start his own successful cattle business.
              </p>
            </Reveal>
            <Reveal>
              <p>
                In May 1844, Bush was asked to serve as a guide, and he and his
                family led 32 people in five wagons to Oregon. Giving up his
                prosperous lifestyle, he used the navigation skills he gained as
                a trapper in the West to successfully bring his wagon train to
                Oregon. Unbeknownst to the party, in the four months and 2,000
                miles it took them to reach Oregon, racial tensions in the
                region had led to the provisional government passing laws that
                excluded Black people from the territory. The caravan was forced
                to go north across the Columbia River to the Washington
                Territory, an unsettled land in the middle of an ownership
                dispute between the US and Great Britain. Eventually settling on
                the Puget Sound’s southernmost tip, in what is now known as
                Tumwater, Washington, Bush declared the area Bush’s Prairie,
                establishing an economic hub by building a gristmill, sawmill,
                and free roadside hotel.
              </p>
            </Reveal>

            <Reveal>
              <p>
                In 1846, the Oregon Treaty was signed, ending the land dispute
                between the two countries, placing the Washington Territory
                firmly on American soil, and clouding the land’s status under
                Oregon’s exclusion laws for more than a decade. Bush’s
                generosity and commitment to public service were so revered that
                when the official Washington government was formed in 1853, one
                of its first orders of business was to request that Congress
                formally recognize Bush’s Prairie, which it did two years later.
              </p>
            </Reveal>
            <Reveal>
              <p>
                This made Bush the first Black landowner in Washington State
                history, though today he’s mostly forgotten.
              </p>
            </Reveal>
            <Reveal>
              <p>
                After he died in 1863, Bush’s six sons kept his traditions alive
                and became prominent in both agriculture and local politics. His
                eldest son, William Owen Bush, served in the Washington State
                Legislature, eventually introducing a bill for a public
                education institution that would become Washington State
                University. Remarking on George Bush, historian Ezra Meeker
                stated that he “was a true American and yet without a country;
                he owed allegiance to the flag and yet the flag would not own
                him; he was firmly held to obey the law and yet the law would
                not protect him, and his oath would not be taken in a court of
                law.”
              </p>
            </Reveal>
            <Reveal>
              <p>
                Bush’s settlement in the Pacific Northwest served as a beacon of
                hope for other African Americans. It inspired others from around
                the country to make their pilgrimage westward and find “manifest
                destiny” like their white counterparts. While this phrase was
                initially used to justify the expansion of slavery to
                “unclaimed” territories, Black people wanted to manifest an
                inclusive future where they were treated with kindness, dignity,
                and respect. Where people could not be bought and sold like
                livestock. The West provided that.
              </p>
            </Reveal>
          </div>
        </div>
        {/* story2 */}
        <div className={styles.story2HeroContainer}>
          <img src={manuelLopes} className={styles.story2Hero} />
        </div>

        <div className={styles.story}>
          <div className={styles.details}>
            <Reveal>
              <h1>02. </h1>
              <div className={styles.p1}>
                In 1858, a Black man, searching for this future and a dream of
                becoming a self-made entrepreneur, stumbled upon a small
                Washington frontier town of fewer than 180 non-indigenous people
                and decided he would set up a barbershop and restaurant. The
                town was eventually renamed Seattle, and Manuel Lopes became the
                first African American to live there, paving the way for other
                Black people across the country to relocate and reside in the
                Pacific Northwest.
              </div>
            </Reveal>
            <Reveal>
              <p>
                A career sailor born in the Cape Verde Islands in 1812, Lopes
                worked on a whaling ship based out of New Bedford,
                Massachusetts, until he made the trip to the West Coast, trading
                one clipper for another. Setting up shop on what is now known as
                First Avenue South, then called Commercial Street, Lopes served
                the local community and other settlers of all races — like
                Dexter Horton, the founder of the first bank in Seattle, and
                Judge Thomas Mercer, another notable Seattle pioneer.
              </p>
            </Reveal>
            <Reveal>
              <p>
                After his wife and his son died prematurely, Lopes focused his
                energies on fostering the local community with his businesses.
                Known for his generosity, Lopes served all walks of life —
                merchants, loggers, miners, and more, giving out free meals
                whenever his customers couldn’t pay. When it was mealtime, it’s
                said Lopes would pace up and down Commercial Street with his
                snare drum, informing the whole city.
              </p>
            </Reveal>

            <Reveal>
              <p>
                Lopes — with his snare — became a local pillar of the community,
                regularly playing in the Fourth of July Parade. And with his
                commitment to uncovering Black excellence in unfamiliar
                territory, he inspired more African Americans to take the
                plunge: Starting in 1861, more Black people began to settle
                north, throwing their hats into barbering. By 1870, six of the
                thirteen black residents of Seattle were barbers, a trade that
                produced the most success for African Americans during the 19th
                century.
              </p>
            </Reveal>
            <Reveal>
              <p>
                Things were good until a struggling economy forced Lopes to move
                to the Kitsap Peninsula (just across Puget Sound in modern-day
                Port Orchard). He eventually settled in Port Gamble, a
                relatively new logging town, where he met and quickly became
                friends with the superintendent of the Puget Mill Company, Cyrus
                Walker. Eventually Lopes became ill, suffering from edema
                symptoms, and was admitted into Seattle’s Providence Hospital in
                1885 to live out the rest of his days. He lived for 10 more
                years before passing away in December 1895 at age 83. He was
                buried in Port Gamble by Walker, just like he was promised.
              </p>
            </Reveal>
            <Reveal>
              <p>
                His obituary read: “At noontime is especially when he made his
                message known, and then it became so common that none but
                strangers in the city thought it remarkable that a drum should
                signal the dinner hour.”
              </p>
            </Reveal>
          </div>
        </div>
      </section>
    </div>
  )
}

export default WeekOne
