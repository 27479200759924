import React from "react"

import styles from "./styles.module.less"

const Credits = ({ children }) => {
  return (
    <div className={styles.body}>
      <div className={styles.title}>Credits</div>
      <div className={styles.links}>{children}</div>
    </div>
  )
}

export default Credits
