import React from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import FirstWeek from "../weekOne"
import SecondWeek from "../weekTwo"
import ThirdWeek from "../weekThree"
import FourthWeek from "../weekFour"

import Credits from "../credits"

import styles from "./styles.module.less"
import "./style.css"

const week2bg =
  "https://ucarecdn.com/124e9ef2-f409-4278-a46f-6c2dddf7acb3/-/format/auto/bgWeek2.webp"

const TabsComp = () => {
  return (
    <div className={styles.Tabs}>
      <Tabs forceRenderTabPanel defaultIndex={3}>
        <TabList>
          <Tab>Week 1</Tab>
          <Tab>Week 2</Tab>
          <Tab>Week 3</Tab>
          <Tab>Week 4</Tab>
        </TabList>

        <TabPanel
          style={{
            backgroundImage: `url("https://eroi-s3.s3.us-west-2.amazonaws.com/thesis-web/bhm-background.png")`,
            backgroundPposition: "center",
            backgroundRepeat: "repeat",
            backgroundSize: "47.5vw",
          }}
        >
          <FirstWeek />
          <BackToTop />
          <Credits style={{ marginTop: "5vw" }}>
            <div>
              <a
                href="http://www.pbs.org/black-culture/explore/10-black-history-little-known-facts/"
                target="_blank"
              >
                http://www.pbs.org/black-culture/explore/10-black-history-little-known-facts/
              </a>
              <br />
              <a href="https://www.historylink.org/File/10435" target="_blank">
                https://www.historylink.org/File/10435
              </a>
              <br />
              <a
                href="https://www.blackpast.org/african-american-history/bush-george-1789-1863/"
                target="_blank"
              >
                https://www.blackpast.org/african-american-history/bush-george-1789-1863/
              </a>
              <br />
              <a
                href="https://www.nps.gov/jeff/learn/historyculture/upload/george_washington_bush.pdf"
                target="_blank"
              >
                https://www.nps.gov/jeff/learn/historyculture/upload/george_washington_bush.pdf
              </a>
              <br />
              <a href="https://www.historylink.org/File/394" target="_blank">
                https://www.historylink.org/File/394
              </a>
              <br />
              <a
                href="https://www.seattlepi.com/lifestyle/article/Seattle-A-welcoming-climate-from-the-beginning-1079282.php"
                target="_blank"
              >
                https://www.seattlepi.com/lifestyle/article/Seattle-A-welcoming-climate-from-the-beginning-1079282.php
              </a>
              <br />
              <a
                href="https://www.blackpast.org/african-american-history/emanuel-lopes-1812-1895/"
                target="_blank"
              >
                https://www.blackpast.org/african-american-history/emanuel-lopes-1812-1895/
              </a>
            </div>
          </Credits>
        </TabPanel>

        <TabPanel
          style={{
            backgroundImage: `url("${week2bg}")`,
            backgroundColor: "#212121",
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
            backgroundBlendMode: "multiply",
          }}
        >
          <SecondWeek />
          <BackToTop />
          <Credits style={{ marginTop: "5vw" }}>
            <div>
              <a href="https://www.webcitation.org/5h6qBfIzo" target="_blank">
                https://www.webcitation.org/5h6qBfIzo
              </a>
              <br />
              <a
                href="https://www.blackpast.org/african-american-history/seattle-steelheads-1946/"
                target="_blank"
              >
                https://www.blackpast.org/african-american-history/seattle-steelheads-1946/
              </a>
              <br />
              <a
                href="https://www.pdxmonthly.com/news-and-city-life/2013/06/the-1945-portland-rosebuds-jun-2013"
                target="_blank"
              >
                https://www.pdxmonthly.com/news-and-city-life/2013/06/the-1945-portland-rosebuds-jun-2013
              </a>
              <br />
              <a
                href="https://www.wweek.com/culture/2021/02/10/in-the-1940s-portland-was-briefly-home-to-an-all-black-baseball-team-called-the-rosebuds-this-year-theyre-coming-back/"
                target="_blank"
              >
                https://www.wweek.com/culture/2021/02/10/in-the-1940s-portland-was-briefly-home-to-an-all-black-baseball-team-called-the-rosebuds-this-year-theyre-coming-back/
              </a>
            </div>
          </Credits>
        </TabPanel>

        <TabPanel
          style={{
            backgroundImage: `url("https://eroi-s3.s3.us-west-2.amazonaws.com/thesis-web/week3-bg.png")`,
            backgroundColor: "#212121",
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
            backgroundBlendMode: "multiply",
          }}
        >
          <ThirdWeek />
          <BackToTop />
          <Credits style={{ marginTop: "5vw" }}>
            <div>
              <a
                href="https://www.oregonhistoryproject.org/articles/historical-records/albina-riot-1967/#.YBn2_2RKhhH"
                target="_blank"
              >
                https://www.oregonhistoryproject.org/articles/historical-records/albina-riot-1967/#.YBn2_2RKhhH
              </a>
              <br />
              <a
                href="https://www.portlandmercury.com/feature/2017/06/21/19105241/burn-the-town-down"
                target="_blank"
              >
                https://www.portlandmercury.com/feature/2017/06/21/19105241/burn-the-town-down
              </a>
              <br />
              <a
                href="https://pdxscholar.library.pdx.edu/cgi/viewcontent.cgi?article=1068&context=usp_fac"
                target="_blank"
              >
                https://pdxscholar.library.pdx.edu/cgi/viewcontent.cgi?article=1068&context=usp_fac
              </a>
              <br />
              <a
                href="http://depts.washington.edu/civilr/BPP.htm"
                target="_blank"
              >
                http://depts.washington.edu/civilr/BPP.htm
              </a>
              <br />
              <a
                href="https://www.marxists.org/history/usa/workers/black-panthers"
                target="_blank"
              >
                https://www.marxists.org/history/usa/workers/black-panthers
              </a>
              <br />
              <a
                href="https://www.seattletimes.com/seattle-news/black-panther-partys-legacy-to-help-sick-poor-lives-on-at-seattle-health-clinic/"
                target="_blank"
              >
                https://www.seattletimes.com/seattle-news/black-panther-partys-legacy-to-help-sick-poor-lives-on-at-seattle-health-clinic/
              </a>
            </div>
          </Credits>
        </TabPanel>

        <TabPanel
          style={{
            // backgroundColor: "linear-gradient(#141717, #212121)",
            backgroundColor: "#2D2D2D",
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat ",
            backgroundBlendMode: "multiply",
            backgroundImage: `url("https://eroi-s3.s3.us-west-2.amazonaws.com/thesis-web/w4-bg.png")`,
          }}
        >
          <FourthWeek />

          <BackToTop />
          <Credits style={{ marginTop: "5vw" }}>
            <div>
              <a
                href="https://www.blackpast.org/african-american-history/jo-ann-hardesty-1957/"
                target="_blank"
              >
                https://www.blackpast.org/african-american-history/jo-ann-hardesty-1957/
              </a>
              <br />
              <a
                href="https://www.blackpast.org/african-american-history/nelson-adrienne-c-1967/"
                target="_blank"
              >
                https://www.blackpast.org/african-american-history/nelson-adrienne-c-1967/
              </a>
              <br />
              <a
                href="https://katu.com/news/local/interview-judge-adrienne-nelson-first-black-oregon-supreme-court-justice"
                target="_blank"
              >
                https://katu.com/news/local/interview-judge-adrienne-nelson-first-black-oregon-supreme-court-justice
              </a>
            </div>
          </Credits>
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default TabsComp

const BackToTop = ({ onClick }) => (
  <a href="#top-tabs-anchor" className={styles.BackToTop} onClick={onClick}>
    <svg viewBox="0 0 28 15">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeOpacity="0.800753934"
      >
        <g
          transform="translate(1.000000, 1.000000)"
          stroke="#ecb77f"
          strokeWidth="2"
        >
          <path d="M13,-2.30926389e-13 L0,13 M13,-2.30926389e-13 L26,13" />
        </g>
      </g>
    </svg>
  </a>
)

// const Week2BG = () => (
//   <svg width="1440px" height="1424px" viewBox="0 0 1440 1424">
//     <g
//       id="V5"
//       stroke="none"
//       stroke-width="1"
//       fill="none"
//       fill-rule="evenodd"
//       opacity="0.4"
//     >
//       <g id="Week-2" transform="translate(0.000000, -1468.000000)">
//         <g id="BG" transform="translate(-230.000000, 1468.000000)"></g>
//       </g>
//     </g>
//   </svg>
// )
