import React from "react"
import Layout from "components/Layout"
import SEO from "components/seo"
import Bhm from "components/ERGLandingPages/BlackHistoryMonth"

const BHM = () => (
  <Layout backgroundColor="#212121" navColor="#ecb77f">
    <SEO
      title=" Thesis | BET: Uncovering Black Excellence"
      keywords={["thesis agency", "portland agency", "marketing agency"]}
      description=""
    />

    <Bhm />
  </Layout>
)

export default BHM
