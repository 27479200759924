import React from "react"
import Reveal from "react-reveal/Reveal"

import JAHmobile from "./img/JAH-mobile.svg"
import ANmobile from "./img/AN-mobile.svg"
import JAH from "./img/JAH.svg"
import AN from "./img/AN.svg"

import podium from "./img/podium.svg"
import silhouette from "./img/silhouette.svg"
import scale from "./img/scale.svg"
import gavel from "./img/gavel.svg"

import styles from "./styles.module.less"

const WeekFour = () => {
  return (
    <div className={styles.body}>
      <section id="week4">
        <div className={styles.weekIntro}>
          <div className={styles.weekHeroCopy}>
            <h1>Rethinking Portland</h1>
            <p>
              Black excellence is making noise, history, and an example, all at
              the same time.
            </p>
            <span className={styles.quote}>
              “Black women are going to have to take more leadership. We are
              prepared because we have a tenaciousness with us. We do not fear
              losing friends, allies, or jobs.” — Rep. Maxine Waters
            </span>
          </div>
        </div>
      </section>
      <Reveal>
        <div className={styles.story1HeroContainerMobile}>
          <img src={JAHmobile} className={styles.story2HeroMobile} />
        </div>

        <div className={styles.story1HeroContainer}>
          <img src={JAH} className={styles.story2Hero} />
        </div>
      </Reveal>

      <div className={styles.story}>
        <div className={styles.details}>
          <Reveal>
            <h1>01. </h1>
            <div className={styles.p1Focus}>
              As inclusive as it may feel, Portland — and Oregon at large —
              harbors a deep history of discrimination and resentment of people
              of color, especially African Americans.
            </div>
            <div>
              <br />
            </div>
          </Reveal>
          <Reveal>
            <div className={styles.columns}>
              <div style={{ flexDirection: "column" }}>
                <p>
                  The state’s ugly history of Black exclusion mandates and
                  “sundown laws” fostered a sense of alienation, from the first
                  Black residents until now. Only recently have Blacks been able
                  to move into positions of power in both local and federal
                  positions and make the progress necessary to nurture a more
                  equitable prospect for the city. Today, we uncover two current
                  examples of Black excellence who are leading the charge.
                </p>
                <p className={styles.focusCopy}>
                  The first Black woman to serve on Portland City Council, Jo
                  Ann Hardesty was also the third Black woman to become an
                  Oregon State Representative.
                </p>
              </div>
              <img src={podium} className={styles.columnImgRT} />
            </div>
          </Reveal>
          <Reveal>
            <p>
              A former naval officer, Hardesty was one of the first women to
              serve on a Navy ship. After her service ended, she moved to Oregon
              and became a Multnomah County Senior Policy Advisor. Committed to
              working with diverse communities for meaningful change and
              inspired by her own low-income background as one of 10 children in
              Baltimore, Hardesty was determined to be a political figure. In
              1996, she ran for a seat in the Oregon House of Representatives
              and won, serving from 1996 to 2003.
            </p>
          </Reveal>

          <div className={styles.columns}>
            <Reveal>
              <img
                src={silhouette}
                className={styles.columnImgLT}
                style={{
                  marginRight: "5%",
                  maxWidth: "375px ",
                }}
              />
              <div style={{ flexDirection: "column" }}>
                <p>
                  Using her knowledge of city policy and passion for justice,
                  Hardesty also became a human rights advocate, serving from
                  2002 to 2010 as the executive director of Action Oregon, an
                  organization focused on affordable housing, immigration
                  rights, and racial equality. In 2007, she started a radio show
                  called <i>Voices from the Edge</i>, discussing sociopolitical
                  issues that faced Portlanders.
                </p>
                <p>
                  This work led her to be named President of the Portland
                  chapter of the NAACP, prior to being elected to City Council
                  in 2019, where to this day she uses her platform to address
                  social change in the city.
                </p>
              </div>
            </Reveal>
          </div>
        </div>
      </div>

      <div className={styles.story2HeroContainerMobile}>
        <img src={ANmobile} className={styles.story2HeroMobile} />
      </div>

      <div className={styles.story2HeroContainer}>
        <img src={AN} className={styles.story2Hero} />
      </div>

      <div className={styles.story}>
        <div className={styles.details}>
          <Reveal>
            <h1>02. </h1>
            <div className={styles.p1}>
              A year prior to Hardesty being sworn into office, Oregon made
              history by appointing the first African American Oregon Supreme
              Court Justice, Adrienne C. Nelson.
            </div>
            <div>
              <br />
            </div>

            <p>
              No stranger to Portland, Nelson moved to the City of Roses after
              graduating summa cum laude from the University of Arkansas and
              obtaining her doctorate in law from the University of Texas, and
              began her law career as a public defender with a nonprofit public
              interest law firm.
            </p>
          </Reveal>

          <div className={styles.columns}>
            <div style={{ flexDirection: "column" }}>
              <Reveal>
                <p className={styles.focusCopy}>
                  In 2006, former Governor Ted Kulongoski appointed Nelson to
                  serve a six-year term on Multnomah County Circuit Court as a
                  trial judge for both criminal and civil trials, making her the
                  second Black woman to serve as a judge in the history of
                  Oregon — and, for a short time, the only Black judge in the
                  state.
                </p>
              </Reveal>
            </div>
            <img src={scale} className={styles.columnImgRT} />
          </div>

          <Reveal>
            <p>
              She served two terms, being reelected in 2012. In 2018, she became
              the first African American to serve in the highest court in
              Oregon, when Governor Kate Brown appointed her to the Oregon
              Supreme Court.
            </p>
            <p>
              Active in both the local and legal communities, Nelson is highly
              respected and recognized for her public service. When asked why
              diversity matters, she said:
            </p>
          </Reveal>
          <div className={styles.columns}>
            <Reveal>
              <img
                src={gavel}
                className={styles.columnImgLT}
                style={{
                  marginRight: "2%",
                  maxWidth: "585px ",
                }}
              />
              {/* <div style={{ flexDirection: "column" }}> */}
              <p className={styles.focusCopy}>
                “It makes a difference for people to see our courts at every
                level are reflective of the communities we serve. It is
                important because it helps break down biases and stereotypes we
                may unintentionally have for one another, so it doesn’t seem
                unusual for somebody who you would never have thought in a
                certain position.”
              </p>
              {/* </div> */}
            </Reveal>
          </div>
        </div>
      </div>

      <Reveal>
        <div className={styles.weekIntro}>
          <div className={styles.weekHeroCopy}>
            <h1>Where Do We Go from Here?</h1>
            <p>Happy is the country with no history.</p>
            <span className={styles.quote}>
              In 2021 and beyond, BET and Thesis look to celebrate Black
              excellence and highlight diversity — not just in February, but
              always. And while we may never be completely satisfied with our
              past, let’s always use our mistakes and missteps as a compass to a
              better future.
            </span>
          </div>
        </div>
      </Reveal>
    </div>
  )
}

export default WeekFour
